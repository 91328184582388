<template>
  <v-app>
    <DashboardCoreAppBar />

    <DashboardCoreDrawer />

    <DashboardCoreView />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./layout/AppBar'),
    DashboardCoreDrawer: () => import('./layout/Drawer'),
    DashboardCoreView: () => import('./layout/View'),
  },

  data: () => ({
    expandOnHover: false,
  }),
}
</script>
